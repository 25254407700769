import {useFormikContext} from 'formik'
import classNames from 'classnames';
import {Listbox, Transition} from '@headlessui/react';
import {Fragment, useEffect, useMemo} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown} from '@fortawesome/pro-light-svg-icons';

interface Props {
    disabled?: boolean;
    name: string;
    label?: string;
    placeholder: string;
    className?: string;
    options: { id: number | string, label: string, disabled?: boolean }[]
}

export const Select = (props: Props) => {
    const formik = useFormikContext()
    const fieldMeta = formik.getFieldMeta(props.name)
    const fieldState = formik.getFieldProps(props.name)
    const value = fieldState.value
    const fieldHelpers = formik.getFieldHelpers(props.name)
    const selectedOption = useMemo(() => {
        const option = props.options.find(o => o.id === value)
        if (option) {
            return option
        } else {
            return undefined
        }
    }, [value, props.options])
    useEffect(() => {
        if (!selectedOption) {
            fieldHelpers.setValue(props.options[0].id)
        }
    }, [selectedOption, fieldHelpers, props.options])
    const isPickedDisabled = !!(selectedOption?.disabled)
    const placeholder = () => (<span className="text-gray-400">{props.placeholder}</span>)
    return (
        <div className={classNames('relative flex flex-col text-[17px]', props.className)}>
            {props.label && <span className="font-black text-rodin-900 mb-2 ml-2">{props.label}</span>}
            <div
                className="relative w-full">
                <Listbox onChange={(option) => fieldHelpers.setValue(option)}
                         value={fieldState.value}
                         disabled={props.disabled}>
                    <Listbox.Button
                        className={classNames('w-full px-4 py-3 text-left rounded-xl border border-gray-400 outline-none focus:border-gold-400 active:border-gold-400 disabled:opacity-40 transition-opacity', {'border-red': isPickedDisabled})}>
                        <span className="block truncate text-rodin-900">{selectedOption?.label ?? placeholder()}</span>
                        <span
                            className="absolute inset-y-0 right-0 top-0 bottom-0 flex items-center pr-4 pointer-events-none">
              <FontAwesomeIcon className="text-gold-500" icon={faChevronDown}/>
            </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options
                            className="absolute z-20 w-full left-0 mt-1 overflow-auto text-base bg-white rounded-xl shadow-2xl sha max-h-60 ring-0 border-gray-400 focus:outline-none sm:text-sm">
                            {props.options.map((option) => (
                                <Listbox.Option
                                    as={Fragment}
                                    disabled={option.disabled}
                                    key={option.id}
                                    value={option.id}
                                >
                                    {({active, selected, disabled}) => (
                                        <li className={classNames('py-3 px-4 select-none cursor-default text-base transition-colors', {
                                            'bg-gold-300 bg-opacity-20': active,
                                            'text-gold-500': selected,
                                            'bg-gray-50 text-gray-300': disabled
                                        })}>{option.label}</li>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </Listbox>
                {isPickedDisabled &&
                    <div className="absolute -bottom-4 text-xs left-2 text-red">Valda staðsetingin er ikki gjørlig til
                        hetta starvið</div>}
            </div>
        </div>
    )
}
