import {useComputedSalary} from '@components/salary-calculator/provider';
import {animated, useSpring} from 'react-spring';
import classNames from 'classnames';
import {useReducedMotion} from 'framer-motion';
import {useMemo} from 'react';

export const SalaryCalculatorOutput = (props: { dark?: boolean }) => {
    const format = useMemo(() => {
        return (value: number) => Math.round(value).toLocaleString('da-dk')
    }, [])
    const calculatedOutput = useComputedSalary()
    const prefersReducedMotion = useReducedMotion()
    const targetValues = useMemo(() => {
        return {
            total: calculatedOutput,
            pension: calculatedOutput * 1.12 * .15,
            vacation: calculatedOutput * .12
        }
    }, [calculatedOutput])
    const {total, pension, vacation} = useSpring({
        total: targetValues.total,
        pension: targetValues.pension,
        vacation: targetValues.vacation,
        config: {frequency: .3, damping: 1},
        immediate: prefersReducedMotion || false,
    })


    return (
        <div className={classNames('pt-4', {dark: props.dark})}>
            <div className="col-span-2 flex flex-col text-white dark:text-rodin-900 items-center">
                <div>
                    <div className="font-normal md:text-2xl text-center md:text-left">Tín vikuløn</div>
                    <div
                        className="md:leading-[150px] md:text-[170px] text-[65px] leading-[65px] font-black text-center">
                        <animated.span key="total">{total.to(n => format(n))}</animated.span>
                        &nbsp;kr.
                        <span className="md:text-[70px] text-[40px] align-top relative bottom-6">*</span></div>
                    <div className="grid grid-cols-2 gap-[24px] pt-12">
                        <div className="flex flex-col items-center leading-tight">
                            <div
                                className="text-sm sm:text-[17px] text-center text-white text-opacity-50 dark:text-rodin-900 dark:text-opacity-70">Umframt
                                eftirløn
                                (15%)
                            </div>
                            <div className="font-black text-[30px] sm:text-[50px]">
                                <animated.span key="pension">{pension.to(n => format(n))}</animated.span>
                                &nbsp;kr.
                            </div>
                        </div>
                        <div className="flex flex-col items-center leading-tight">
                            <div
                                className="text-sm sm:text-[17px] text-center text-white text-opacity-70 dark:text-rodin-900 dark:text-opacity-70">Umframt
                                frítíðarløn
                                (12%)
                            </div>
                            <div className="font-black text-[30px] sm:text-[50px]">
                                <animated.span key="vacation">{vacation.to((n) => format(n))}</animated.span>
                                &nbsp;kr.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
