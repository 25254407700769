import classNames from 'classnames';
import {useCalculatorInput, useHasNightShift} from '@components/salary-calculator/provider';

export const HoursOutput = () => {
    const {day, night} = useCalculatorInput()
    const hasNightShift = useHasNightShift()
    const dayShiftTotal = day.sunday + day.saturday + day.weekdays
    const nightShiftTotal = night.sunday + night.saturday + night.weekdays
    const nightShiftCounted = hasNightShift ? nightShiftTotal : 0

    return (
        <ul>
            <li className="flex justify-between">
                <span>Dagvakt</span>
                <output>{dayShiftTotal} tímar</output>
            </li>
            <li className={classNames('flex justify-between border-b mb-2 pb-2 transition-colors', {'text-[#C1C1C1]': !hasNightShift})}>
                <span>Kvøldvakt</span>
                <output>{hasNightShift ? nightShiftTotal : '-'} tímar</output>
            </li>
            <li className="flex justify-between">
                <span className="font-black text-rodin-900">Tilsamans</span>
                <output  className="font-black text-rodin-900">{dayShiftTotal + nightShiftCounted} tímar</output>
            </li>
        </ul>
    )
}
