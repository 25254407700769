import {QodioLogo} from '@components/qodio-logo';
import Link from 'next/link';

export const PageFooter = () => {
    return (
        <footer className="bg-rodin-900">
            <div
                className="px-4 py-2 mx-auto max-w-screen-xl flex justify-between text-white text-opacity-50 items-center text-sm">
                <Link passHref={true} href="/privatlivspolitikkur"><a>Privatlívspolitikkur</a></Link>
                <QodioLogo className="fill-white opacity-50"/>
            </div>
        </footer>
    )
}
