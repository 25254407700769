import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Image from 'next/image'
import {useRef, useState} from 'react';
import {animated, useSpring} from 'react-spring';
import useMeasureDirty from 'react-use/lib/useMeasureDirty';
import {faClose} from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';

export const StickyHelper = () => {
    const contentRef = useRef(null)
    const [isResting, setIsResting] = useState(true)
    const [expanded, setExpanded] = useState(false)
    const {height} = useMeasureDirty(contentRef)
    const spring = useSpring({
        height: expanded ? height : 0, opacity: expanded ? 1 : 0, onStart: () => {
            setIsResting(false)
        },
        onRest: () => {
            setIsResting(true)
        }
    })
    return (
        <div className="fixed bottom-8 right-2 lg:right-24 lg:bottom-12 cursor-pointer z-40 flex flex-col items-end"
             title="Hevur tú spurningar?"
             role="dialog">
            <div className="z-50 relative"
                 onClick={() => setExpanded(e => true)}>
                <div className="w-24 h-24 lg:w-[125px] lg:h-[125px] rounded-full overflow-hidden shadow-lg">
                    <Image alt="Smílandi kvinna" src="/sticky_portrait.jpg" quality={100} width={125} height={125} layout={'fixed'} priority={true}/>
                </div>
                <div
                    className="absolute right-[90px] md:right-24 right-16 -top-1 w-8 h-8 lg:w-12 lg:h-12 bg-gradient-to-r from-gold-500 to-gold-400 rounded-full flex items-center justify-center font-black text-white text-xl lg:text-3xl z-50">?
                </div>
            </div>

            <animated.div style={spring} aria-hidden={!expanded}
                          className={classNames("overflow-hidden ml-2 bg-white rounded-xl relative -top-4 shadow-xl",{'hidden':isResting && !expanded})}>
                <div ref={contentRef} className="relative">
                    <div className="sm:max-w-xs max-w-full px-4 py-4 z-0 relative">
                        <div className="text-rodin-900 font-black text-[17px]">
                            Spurningar?
                        </div>
                        <div className="text-sm py-2">
                            Hey, eg eiti Beinta.
                            Hevur tú spurningar, ert tú vælkomin at seta teg í samband við meg við at senda ein
                            teldupost á
                            beintad@rodin.fo, ella við at ringja á 47 82 20.
                        </div>
                        <div className="flex justify-end absolute bottom-2 right-2">
                            <button className="w-8 h-8 rounded-md  focus:bg-black focus:bg-opacity-10 !outline-none"
                                    aria-label="Loka infoboks"
                                    onClick={() => setExpanded(false)}><FontAwesomeIcon icon={faClose}/></button>
                        </div>
                    </div>
                </div>
            </animated.div>
        </div>)
}
