import {useDayzed} from 'dayzed';
import {useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import {
    faCalendarDays,
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faChevronLeft,
    faChevronRight
} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {format, isAfter, parse} from 'date-fns';
import {Field, useFormikContext} from 'formik';
import {useClickAway, useKeyPressEvent} from 'react-use';

interface Props {
    autocomplete?: string;
    minDate?: Date;
    maxDate?: Date;
    name: string;
    label: string;
    navYears?: boolean;
    placeholder: string;
}


const monthNamesShort = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Maj',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
];
const weekdayNamesShort = ['Mán', 'Týs', 'Mik', 'Hós', 'Frí', 'Ley', 'Sun'];

export const DatePicker = (props: Props) => {
    const now = useMemo(() => new Date(), [])
    const initialDate = useMemo(() => {
        return props.minDate ? (isAfter(props.minDate, now) ? now : props.minDate) : now
    }, [props.minDate, now])
    const form = useFormikContext()
    const fieldHelpers = form.getFieldHelpers(props.name)
    const pickerRef = useRef(null)
    useClickAway(pickerRef, () => setShowPicker(false))
    const field = form.getFieldProps(props.name)
    useKeyPressEvent((event) => event.key === 'Escape', (event) => {
        if (showPicker) {
            setShowPicker(false)
            event.stopPropagation()
        }
    })
    const [showPicker, setShowPicker] = useState(false)
    const parsedValue = useMemo(() => {
        return field.value ? parse(field.value, 'dd/MM/yyyy', new Date()) : undefined
    }, [field.value])
    const {calendars, getDateProps, getBackProps, getForwardProps} = useDayzed({
        firstDayOfWeek: 1,
        minDate: props.minDate, maxDate: props.maxDate, date: initialDate, onDateSelected(selectedDate, event) {
            fieldHelpers.setValue(format(selectedDate.date, 'dd/MM/yyyy'))
            setShowPicker(false)
        },
        selected: parsedValue
    })
    return (
        <label className="relative flex flex-col text-[17px]">
            <span className="font-black text-rodin-900 mb-2 ml-2">{props.label}</span>
            <div className="relative w-full">
                <Field name={props.name}
                       autoComplete={props.autocomplete}
                       placeholder={props.placeholder}
                       className="px-4 py-3 w-full pr-14 overflow-hidden rounded-xl border border-gray-400 outline-none focus:border-gold-400"/>
                <button onClick={() => setShowPicker(true)}
                        className="absolute w-12 top-1 bottom-1 right-1 rounded-xl text-gold-500 hover:bg-gray-100"
                        type="button"><FontAwesomeIcon
                    icon={faCalendarDays}/></button>
            </div>
            {showPicker &&
                <div ref={pickerRef}
                     className="absolute top-20 mt-2 z-20 bg-white max-w-md w-full rounded-lg shadow-2xl">
                    {calendars.map(calendar => (
                        <div
                            key={`${calendar.month}${calendar.year}`}
                            className="bg-white rounded-xl shadow-2xl px-6 py-4"
                        >
                            <div className="flex justify-between items-center border-b pb-2">
                                <div>
                                    {props.navYears && <button type="button"
                                            className="w-8 h-8  hover:text-gold-500 hover:bg-gray-50 transition-colors rounded-md  cursor-pointer" {...getBackProps({
                                        calendars,
                                        offset: 12
                                    })}>
                                        <FontAwesomeIcon icon={faChevronDoubleLeft}/></button>}
                                    <button type="button"
                                            className="w-8 h-8 hover:text-gold-500 hover:bg-gray-50 transition-colors rounded-md  cursor-pointer" {...getBackProps({calendars})}>
                                        <FontAwesomeIcon icon={faChevronLeft}/></button>
                                </div>
                                <div
                                    className="text-rodin-900 font-black">{monthNamesShort[calendar.month]} {calendar.year}</div>
                                <div>
                                    <button type="button"
                                            className="w-8 h-8  hover:text-gold-500 hover:bg-gray-50 transition-colors rounded-md cursor-pointer" {...getForwardProps({calendars})}>
                                        <FontAwesomeIcon icon={faChevronRight}/></button>
                                    {props.navYears && <button type="button"
                                                               className="w-8 h-8  hover:text-gold-500 hover:bg-gray-50 transition-colors rounded-md  cursor-pointer" {...getForwardProps({
                                        calendars,
                                        offset: 12
                                    })}>
                                        <FontAwesomeIcon icon={faChevronDoubleRight}/></button>}
                                </div>
                            </div>
                            {weekdayNamesShort.map(weekday => (
                                <div
                                    key={`${calendar.month}${calendar.year}${weekday}`}
                                    className="font-black inline-block text-center text-rodin-900"
                                    style={{
                                        width: 'calc(100% / 7)',
                                    }}
                                >
                                    {weekday}
                                </div>
                            ))}
                            {calendar.weeks.map((week, weekIndex) =>
                                week.map((dateObj, index) => {
                                    let key = `${calendar.month}${
                                        calendar.year
                                    }${weekIndex}${index}`;
                                    if (!dateObj) {
                                        return (
                                            <div
                                                key={key}
                                                style={{
                                                    display: 'inline-block',
                                                    width: 'calc(100% / 7)',
                                                    border: 'none',
                                                    background: 'transparent'
                                                }}
                                            />
                                        );
                                    }
                                    let {date, selected, selectable, today} = dateObj;
                                    return (
                                        <button
                                            type="button"
                                            style={{
                                                display: 'inline-block',
                                                width: 'calc(100% / 7)',
                                                border: 'none',
                                            }}
                                            className={classNames('rounded-md text-rodin-900 hover:bg-gray-100 transition-colors', {
                                                'opacity-20': !selectable,
                                                'font-black': today,
                                                'text-gold-500 bg-gold-500 bg-opacity-10': selected
                                            })}
                                            key={key}
                                            {...getDateProps({dateObj})}
                                            // onClick={() => (dateObj.date)}
                                        >
                                            {date.getDate()}
                                        </button>
                                    );
                                })
                            )}
                        </div>
                    ))}
                </div>}
        </label>
    )
}
