import {ErrorMessage, Field as FormikField, useFormik, useFormikContext} from 'formik'
import classNames from 'classnames';

interface Props {
    name: string;
    label: string;
    placeholder: string;
    className?: string;
    type?: string;
    autocomplete?: string;
}

export const Field = (props: Props) => {
    const form = useFormikContext()
    const field = form.getFieldMeta(props.name)
    const isInvalid = field.error && field.touched
    return (
        <label className={classNames('relative flex flex-col  text-[17px]', props.className)}>
            <span className="font-black text-rodin-900 mb-2 ml-2">{props.label}</span>
            <FormikField name={props.name}
                         autoComplete={props.autocomplete}
                         type={props.type}
                         placeholder={props.placeholder}
                         className={classNames('px-4 py-3 rounded-xl transition-colors   border outline-none border-gray-400 focus:border-gold-400',{'border-red focus:border-red': isInvalid})}/>
            <span className="absolute text-red -bottom-4 left-2 text-xs"><ErrorMessage name={props.name}/></span>
        </label>
    )
}
