import classNames from 'classnames';

interface Props {
    className?: string;
}

export const QodioLogo = (props: Props) => {
    const height = 20;
    return <div role="img" aria-label="QODIO Logo" className={classNames(props.className)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="67" height={15}
             aria-hidden={true}
             viewBox="0 0 155 36">
            <path id="LOGO"
                  d="M836.173,94h-6.9V61h6.9V94Zm-28-33h-9.736V94h9.736A16.5,16.5,0,1,0,808.172,61Zm1.459,25.88h-4.292V68.119h4.292c4.3,0.366,7.691,4.442,7.691,9.381S813.933,86.513,809.631,86.88Zm-52.486-9.5a16.569,16.569,0,1,0-16.568,16.378,16.693,16.693,0,0,0,5.737-1.01L747.121,94H755.3l-3.155-4.9A16.235,16.235,0,0,0,757.145,77.378Zm-25.935,0a9.368,9.368,0,1,1,9.367,9.259A9.314,9.314,0,0,1,731.21,77.378ZM857.3,61a16.5,16.5,0,1,0,16.691,16.5A16.6,16.6,0,0,0,857.3,61Zm0,25.828a9.329,9.329,0,1,1,9.436-9.329A9.383,9.383,0,0,1,857.3,86.828ZM777.368,61a16.5,16.5,0,1,0,16.691,16.5A16.6,16.6,0,0,0,777.368,61Zm0,25.828A9.329,9.329,0,1,1,786.8,77.5,9.383,9.383,0,0,1,777.368,86.828Z"
                  transform="translate(-719 -60)"/>
        </svg>
    </div>
}
