import Image from 'next/image';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope, faPhoneFlip} from '@fortawesome/pro-solid-svg-icons';
import Link from 'next/link';

export const PageHeader = () => {
    return (
        <div
            className="px-4 sm:pt-8 pt-[60px] py-8 mx-auto max-w-screen-xl flex items-center justify-between relative z-20">
            <Link href="/" passHref={true}><a aria-label="Roðin búmerki"
                                              className="h-[32px] w-[96px] sm:w-[157px] sm:h-[53px]">
                <Image src="/rodin_logo.svg" alt="Roðin Búmerki"
                       width={157}
                       layout={'responsive'}
                       height={53}/>
            </a></Link>
            <div className="text-white font-black md:flex text-xl gap-16 hidden">
                <a className="block cursor-pointer hover:text-gray-300 transition-colors" href="tel:00298478200">
                    <FontAwesomeIcon height={13} width={13} icon={faPhoneFlip}
                                     className="mr-4"/>47&nbsp;82&nbsp;00
                </a>
                <a className="block cursor-pointer hover:text-gray-300 transition-colors" href="mailto:rodin@rodin.fo">
                    <FontAwesomeIcon height={13} width={13} icon={faEnvelope} className="mr-4"/>rodin@rodin.fo
                </a>
            </div>
        </div>
    )
}
