import {Field} from 'formik'
import classNames from 'classnames';

interface Props {
    name: string;
    label: string;
    placeholder: string;
    className?: string;
    type?: string;
    as?: string;
}

export const TextArea = (props: Props) => {
    return (
        <label className={classNames('flex flex-col  text-[17px]', props.className)}>
            <span className="font-black text-rodin-900 mb-2 ml-2">{props.label}</span>
            <Field name={props.name}
                   as="textarea"
                   rows={3}
                   type={props.type}
                   placeholder={props.placeholder}
                   className="px-4 py-3 rounded-xl border border-gray-400 outline-none focus:border-gold-400 resize-none"/>
        </label>
    )
}
