export enum Position {
    Roktarstarvsfolk = 'roktarstarvsfolk',
    Reingerdisfolk = 'reingerdisfolk',
    Koksfolk = 'koksfolk'
}

export type ShiftHours = ShiftValue<number>

export type ShiftValue<T> = {
    weekdays: T;
    saturday: T;
    sunday: T;
}
