import {Formik, useFormikContext} from 'formik'
import {ReactNode, useMemo} from 'react';
import {zipWith} from 'lodash'
import {add, dinero, Dinero, multiply, toUnit} from 'dinero.js'
import {DKK} from '@dinero.js/currencies'
import {Position, ShiftHours, ShiftValue} from 'lib/types';

export interface SalaryCalculatorData {
    position: Position;
    education: string;
    day: ShiftHours;
    night: ShiftHours;
}


const formInitial: SalaryCalculatorData = {
    position: Position.Roktarstarvsfolk,
    education: 'none',
    day: {
        weekdays: 16,
        saturday: 2,
        sunday: 0
    },
    night: {
        weekdays: 0,
        saturday: 0,
        sunday: 0
    }
}


export const SalaryCalculatorProvider = (props: { children: ReactNode, initialValues: Partial<SalaryCalculatorData> | null, onSubmit: (values: SalaryCalculatorData) => Promise<void> }) => {
    const onSubmit = async (values: typeof formInitial) => {
        await props.onSubmit(values)
    }
    const initial = useMemo(() => Object.assign({}, formInitial, props.initialValues), [props.initialValues])

    return (
        <Formik initialValues={initial} onSubmit={onSubmit}>
            {props.children}
        </Formik>
    )
}

export function useCalculatorInput() {
    return useFormikContext().values as typeof formInitial
}

export function useHasNightShift(): boolean {
    return useCalculatorInput().position === Position.Roktarstarvsfolk
}

interface Hours {
    day: ShiftHours;
    night: ShiftHours;
}


const HOURS_ZERO: ShiftHours = {
    weekdays: 0,
    saturday: 0,
    sunday: 0
}

interface Rates {
    day: ShiftRates;
    night: ShiftRates;
}

type ShiftRates = ShiftValue<Dinero<number>>

const DEFAULT_RATES: Rates = buildRates({
    day: {
        weekdays: 146_66,
        saturday: 186_99,
        sunday: 219_99
    },
    night: {
        weekdays: 192_28,
        saturday: 232_61,
        sunday: 265_61
    }
})


const RATES_HEILSUHJALP: Rates = buildRates({
    day: {
        weekdays: 168_52,
        saturday: 214_86,
        sunday: 252_78,
    },
    night: {
        weekdays: 220_94,
        saturday: 267_28,
        sunday: 305_20
    }
})


const RATES_HEILSUROKT: Rates = buildRates({
    day: {
        weekdays: 180_33,
        saturday: 229_92,
        sunday: 270_50,
    },
    night: {
        weekdays: 236_43,
        saturday: 286_02,
        sunday: 326_60
    }
})

const RATES_SJUKRAROKT: Rates = buildRates({
    day: {
        weekdays: 194_74,
        saturday: 240_89,
        sunday: 292_11,
    },
    night: {
        weekdays: 249_48,
        saturday: 295_63,
        sunday: 346_85
    }
})

function buildRates(rates: { day: ShiftValue<number>, night: ShiftValue<number> }): Rates {
    return inflate(flatten(rates).map(r => dinero({amount: r, currency: DKK})))
}

export function useRates(): Rates {
    return DEFAULT_RATES
}

const RATES_KITCHEN = buildRates({
    day: {weekdays: 133_89, saturday: 220_92, sunday: 267_78},
    night: {weekdays: 0, sunday: 0, saturday: 0}
})

const RATES_CLEANING = buildRates({
    day: {weekdays: 133_89, saturday: 220_92, sunday: 267_78},
    night: {weekdays: 0, sunday: 0, saturday: 0}
})


export function useRateClass(position: Position, education: string | null) {
    return useMemo(() => {
        if (position === Position.Koksfolk) {
            switch (education) {
                case 'fodsla':
                    return RATES_KITCHEN
                default:
                    return DEFAULT_RATES
            }
        } else if (position === Position.Reingerdisfolk) {
            return RATES_CLEANING
        } else {
            switch (education) {
                case 'heilsurokt':
                    return RATES_HEILSUROKT;
                case 'sjukrarokt':
                    return RATES_SJUKRAROKT;
                case 'heilsuhjalp':
                    return RATES_HEILSUHJALP;
                default:
                    return DEFAULT_RATES
            }
        }
    }, [position, education])
}

function inflate<T>(raw: T[]): { day: ShiftValue<T>, night: ShiftValue<T> } {
    return {
        day: {
            weekdays: raw[0],
            saturday: raw[1],
            sunday: raw[2]
        },
        night: {
            weekdays: raw[3],
            saturday: raw[4],
            sunday: raw[5]
        },
    }
}

function flatten<T>(shiftLike: { day: ShiftValue<T>, night: ShiftValue<T> }): T[] {
    const {day, night} = shiftLike
    return [day.weekdays, day.saturday, day.sunday, night.weekdays, night.saturday, night.sunday]
}

/** Pure */
function computeSalaryTotal(hours: Hours, rates: Rates): number {
    const amount = toUnit(zipWith(flatten(hours), flatten(rates), (hour, rate) => multiply(rate, hour))
        .reduce((r1: Dinero<number>, r2: Dinero<number>) => add(r1, r2), dinero({
            amount: 0,
            currency: DKK
        })))
    if (amount > 1000 && amount % 10 === 0) {
        return amount - 1
    } else {
        return amount
    }

}


export function useComputedSalary() {
    const input = useCalculatorInput()
    const rates = useRateClass(input.position, input.education)
    const hours = useCalculatorInput()
    const hasNightShift = useHasNightShift()
    return computeSalaryTotal({day: hours.day, night: hasNightShift ? hours.night : HOURS_ZERO}, rates)
}

