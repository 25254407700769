import {useSlider, useSliderThumb} from '@react-aria/slider';
import {SliderState, useSliderState} from '@react-stately/slider';
import {useFocusRing} from '@react-aria/focus';
import {VisuallyHidden} from '@react-aria/visually-hidden';
import {mergeProps} from '@react-aria/utils';
import {useNumberFormatter} from '@react-aria/i18n';
import React, {MutableRefObject, ReactNode} from 'react';
import classNames from 'classnames';
import {useSpring} from '@react-spring/core';
import {animated} from 'react-spring';
import {NumberFormatOptions} from '@internationalized/number';
import {useFormikContext} from 'formik';

interface SliderProps {
    maxValue: number;
    minValue: number;
    step: number;
    name: string;
    formatOptions?: NumberFormatOptions;
    label: string;
    zeroOnDisable?: boolean;
    disabled?: boolean;
    renderLabel?: (value: number) => ReactNode;
}

export function Slider(props: SliderProps) {
    const formik = useFormikContext<any>()
    let trackRef = React.useRef(null);
    let numberFormatter = useNumberFormatter(props.formatOptions);

    let state = useSliderState({
        ...props,
        isDisabled: props.disabled,
        numberFormatter,
        defaultValue: [formik.getFieldMeta(props.name)!.initialValue as number],
        onChange: (value) => formik.setFieldValue(props.name, value[0], false)
    });

    let {
        groupProps,
        trackProps,
        labelProps,
        outputProps
    } = useSlider(props, state, trackRef);
    const trackFillSpring = useSpring({
        width: (state.getThumbPercent(0) * 100) + '%',
        config: {frequency: .3, damping: 1}
    })

    return (
        <div
            {...groupProps}
            style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                touchAction: 'none'
            }}
        >
            <div
                className={classNames('flex self-stretch text-[#464646] transition-colors', {'text-[#c1c1c1]': props.disabled})}>
                {props.label &&
                    <label {...labelProps}>{props.label}</label>}
                <output {...outputProps} style={{flex: '1 0 auto', textAlign: 'end'}}>
                    {props.renderLabel ? props.renderLabel(state.getThumbValue(0)) : state.getThumbValueLabel(0)}
                </output>
            </div>
            {/* The track element holds the visible track line and the thumb. */}
            <div
                {...trackProps}
                ref={trackRef}
                className="relative w-full h-[30px]"
            >
                <div className="absolute h-[5px] w-full rounded-md overflow-hidden top-[12px] bg-[#F6F6F6]"/>
                <animated.div
                    className={classNames('absolute h-[5px] rounded-md overflow-hidden bg-gradient-slight from-gold-400 to-gold-500 w-full top-[12px] left-0 transition-opacity', {'opacity-0': props.disabled})}
                    style={trackFillSpring}/>
                <Thumb index={0} state={state} trackRef={trackRef} disabled={props.disabled}/>
            </div>
        </div>
    );
}

interface ThumbProps {
    index: number;
    trackRef: MutableRefObject<any>;
    state: SliderState;
    disabled?: boolean;
}

function Thumb(props: ThumbProps) {
    let {state, trackRef, index, disabled} = props;
    let inputRef = React.useRef(null);
    let {thumbProps, inputProps} = useSliderThumb({
        index,
        trackRef,
        inputRef
    }, state);

    let {focusProps, isFocusVisible} = useFocusRing();
    const thumbSpring = useSpring({
        left: (state.getThumbPercent(index) * 100) + '%',
        config: {frequency: .3, damping: 1}
    })
    return (
        <animated.div
            style={{
                position: 'absolute',
                top: 6,
                transform: 'translateX(-50%)',
                ...thumbSpring
            }}
        >
            <div
                {...thumbProps}
                className={classNames('transition-all cursor-pointer w-4 h-4 rounded-full bg-[#f6f6f6] shadow-[1px_1px_7px_0_rgba(0,0,0,.41)]', {'ring-4 ring-gold-400': isFocusVisible}, {'shadow-none': disabled})}
            >
                <VisuallyHidden>
                    <input ref={inputRef} {...mergeProps(inputProps, focusProps)} />
                </VisuallyHidden>
            </div>
        </animated.div>
    );
}
