import {Position} from 'lib/types';

export const KITCHEN_EDUCATIONS = [{
    id: 'none',
    label: 'Uttan útbúgving'
}, {
    id: 'fodsla',
    label: 'Føðsluatstøðingur'
}]

export const CLEANING_EDUCATIONS = [{
    id: 'none',
    label: 'Uttan útbúgving'
}]

export const EDUCATION_OPTIONS = [{
    id: 'none',
    label: 'Uttan útbúgving'
}, {
    id: 'sjukrarokt',
    label: 'Sjúkrarøktarfrøðingur'
}, {
    id: 'heilsurokt',
    label: 'Heilsurøktari'
}, {
    id: 'heilsuhjalp',
    label: 'Heilsuhjálpari'
}]

export interface Location {
    id: number;
    label: string;
}

const LOCATIONS: Location[] = [
    {
        id: 0,
        label: 'Einki ynski'
    }, {
        id: 1,
        label: 'Heimatænastan',
    }, {
        id: 2,
        label: 'Sjóvarlon',
    }, {
        id: 3,
        label: 'Vesturskin',
    }, {
        id: 4,
        label: 'Eysturoyar røktar- og ellisheim',
    }
]

function getEducationOptions(position: Position): Education[] {
    switch (position) {
        case Position.Koksfolk:
            return KITCHEN_EDUCATIONS
        case Position.Reingerdisfolk:
            return CLEANING_EDUCATIONS
        case Position.Roktarstarvsfolk:
            return EDUCATION_OPTIONS
    }
}

async function getLocationOptions(): Promise<Location[]> {
    return Promise.resolve(LOCATIONS)
}

export interface Education {
    id: string;
    label: string;
}


export const API = {
    getEducationOptions,
    getLocationOptions
}
