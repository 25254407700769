import {CSSProperties, ForwardedRef, forwardRef, ReactNode} from 'react';
import classNames from 'classnames';
import UseAnimations from 'react-useanimations';
import spinner from 'react-useanimations/lib/loading'

interface Props {
    children: ReactNode;
    icon?: ReactNode;
    type?: 'button' | 'submit';
    onClick?: () => void | (() => Promise<void>)
    className?: string;
    loading?: boolean;
    style?: CSSProperties;
    disabled?: boolean;
}

export const Button = forwardRef((props: Props, ref: ForwardedRef<HTMLButtonElement>) => {
    return (
        <button
            style={props.style}
            ref={ref}
            onClick={props.onClick}
            type={props.type}
            disabled={props.loading || props.disabled}
            className={classNames('group relative overflow-hidden flex items-center justify-center whitespace-nowrap',
                'px-6 py-4 lg:px-8 lg:py-6 text-[20px] lg:text-[2rem] rounded-full',
                'transition-all',
                'font-black text-white leading-[1rem]',
                {'bg-gradient-to-bl from-gold-400 to-gold-500': (!props.disabled || props.loading)},
                {'bg-gray-400 opacity-50': (props.disabled)},
                {'cursor-not-allowed': props.loading},
                'outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gold-500',
                props.className
            )}>
            {!props.disabled && <div
                className="absolute inset-0 rounded-full bg-gold-500 group-hover:opacity-100 ease-linear opacity-0 transition-opacity duration-200"
                aria-hidden={true}/>}
            {!props.disabled && <div
                className="absolute inset-0 rounded-full bg-black group-hover:opacity-10 ease-linear opacity-0 transition-opacity duration-200"
                aria-hidden={true}/>}
            <div className="relative flex items-center">
                <span className={classNames({'invisible': props.loading})}>{props.children}</span>
                {props.loading &&
                    <span
                        className="pointer-events-none absolute inset-0 flex items-center justify-center"><UseAnimations
                        size={32}
                        strokeColor="#ffffff"
                        animation={spinner}/></span>}
                {props.icon &&
                    <div
                        className="ml-2 sm:ml-4 bottom-0 top-0 pointer-events-none flex items-center">{props.icon}</div>}
            </div>
        </button>
    )
})

Button.displayName = 'RodinButton';
