import classNames from 'classnames';
import {Field, Form} from 'formik';
import {useCalculatorInput, useHasNightShift} from '@components/salary-calculator/provider';
import {Button} from '@components/button';
import {faHandHoldingMedical, faHandsBubbles, faUtensils} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {SalaryCalculatorOutput} from '@components/salary-calculator/output';
import {useMedia} from 'react-use';
import {HoursInput} from '@components/salary-calculator/hours-input';
import {HoursOutput} from '@components/salary-calculator/hours-output';
import {Select} from '@components/form';
import {API, EDUCATION_OPTIONS} from 'lib/api';
import {Position} from 'lib/types';
import {useEffect, useRef, useState} from 'react';
import splitbee from '@splitbee/web';

interface TabProps {
    title: string;
    name: string;
    icon: IconDefinition;
    value: string;
}

const Tab = (props: TabProps) => {
    const active = useCalculatorInput().position === props.value;
    const tabClasses = (active: boolean) => classNames('select-none cursor-pointer text-xl md:text-[1.75rem] lg:text-[2rem] font-black sm:grow sm:basis-1',
        'rounded-t-[20px] sm:rounded-t-[30px] px-6 sm:px-6 py-4',
        'flex justify-center items-center',
        {
            'bg-white text-rodin-900 grow shrink-0 !px-3': active,
            'bg-gradient-slight from-gold-500 to-gold-400 text-white shrink': !active
        })
    return (
        <label className={tabClasses(active)}>
            <Field name={props.name} type="radio" value={props.value} className="sr-only"/>
            <span className={classNames({'hidden sm:inline': !active, 'inline': active})}>{props.title}</span>
            <span className={classNames({'hidden': active, 'inline sm:hidden': !active})}>
                    <FontAwesomeIcon icon={props.icon} size="lg"/>
                </span>
        </label>
    )
}

export const Tabs = () => {
    return (
        <div
            className="flex space-x-2 sm:grid sm:grid-cols-3 sm:gap-[8px] sm:space-x-0 md:grid md:grid-cols-3 md:gap-[24px] md:space-x-0">
            <Tab title="Røktarfólk" icon={faHandHoldingMedical} name="position"
                 value={Position.Roktarstarvsfolk}/>
            <Tab title="Reingerðisfólk" icon={faHandsBubbles} value={Position.Reingerdisfolk} name="position"/>
            <Tab title="Køksfólk" icon={faUtensils} value={Position.Koksfolk} name="position"/>
        </div>
    )
}

export const SalaryCalculator = () => {
    const touched = useRef(false)

    const {day, night, position} = useCalculatorInput()
    const hasNightShift = useHasNightShift()
    const dayShiftTotal = day.sunday + day.saturday + day.weekdays
    const nightShiftTotal = night.sunday + night.saturday + night.weekdays
    const nightShiftCounted = hasNightShift ? nightShiftTotal : 0
    const isDesktop = useMedia('(min-width: 768px)')
    const educationOptions = API.getEducationOptions(position)
    const hasEducation = position !== Position.Reingerdisfolk
    return (
        <div>
            <Form>
                <Tabs/>
                <div className="bg-white rounded-b-[20px] md:rounded-b-[30px]">
                    <div className="py-5">
                        <div className="flex w-full grid grid-cols-1 md:grid-cols-3 sm:gap-[24px]">
                            <div className="pl-9 pr-8">
                                <Select name="education" placeholder="Uttan útbúgving"
                                        disabled={!hasEducation}
                                        options={educationOptions}/>
                            </div>
                        </div>
                        <div className="grid md:grid-cols-3 gap-6 py-4">
                            <div className="ml-9 mr-8">
                                <h3 className="text-rodin-900 font-black mb-3">Dagvakt</h3>
                                <div className="grid grid-rows-3 gap-2">
                                    <HoursInput group="day"/>
                                </div>
                            </div>
                            <div className="mx-8 mb-8">
                                <h3 className={classNames('text-rodin-900 font-black mb-3 transition-colors', {'text-[#C1C1C1]': !hasNightShift})}>Kvøldvakt</h3>
                                <div className="grid grid-rows-3 gap-2">
                                    <HoursInput group="night" disabled={!hasNightShift}/>
                                </div>
                            </div>
                            <div className="mx-8 mr-9 flex flex-col">
                                <h3 className="text-rodin-900 font-black mb-3">Tímatal um
                                    vikuna</h3>
                                <HoursOutput/>
                            </div>
                        </div>
                        <SalaryCalculatorOutput dark/>
                        <div className="px-6 grid md:grid-cols-3 my-8 sm:gap-[24px]">
                            <Button className="mt-auto w-full md:col-start-2">Søk starvið</Button>
                        </div>
                    </div>
                </div>
                {/*{isDesktop && <SalaryCalculatorOutput/>}*/}
            </Form>
        </div>
    )
}
